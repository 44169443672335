import { css, Typography, useTheme } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { HTMLAttributes } from 'react';

import { useMediaQuery } from '~/src/hooks/v1/Common/useMediaQuery';

import { CompareTable } from '../../components/CompareTable/CompareTable';

export type CompareSectionProps = HTMLAttributes<HTMLDivElement>

export const CompareSection = (props: CompareSectionProps) => {
  const theme = useTheme();
  const laptop = useMediaQuery('laptop');
  const { t } = useTranslation('welcome');

  return (
    <section
      { ...props }
      className={classNames(
        'CompareSection',
        props.className
      )}
      css={css`
        background-color: ${theme.app.colors['primary-color-05']};
      `}
    >
      <div
        className="CompareSection__Wrapper"
        css={css`
          max-width: 1136px;
          padding: 145px 45px 60px;
          margin: auto;
          width: 100%;
          ${laptop.down.mq} {
            padding: 60px 20px;
          }
        `}
      >
        <div
          className="CompareSection__TitleWrapper"
          css={css`
            text-align: center;
            margin-bottom: 66px;
            ${laptop.down.mq} {
              margin-bottom: 40px;
            }
          `}
        >
          <Typography
            variant={laptop.down.is ? 'h3' : 'h2'}
            css={css`
              color: ${theme.app.colors['neutral-color-01']};
            `}
          >
            {t('compare.title')}
          </Typography>
        </div>
        <div className="CompareSection__TableWrapper">
          <CompareTable
            css={css`
              width: 100%;
            `}
          />
        </div>
      </div>
    </section>
  );
};
