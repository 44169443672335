import { css } from '@mui/material';
import classNames from 'classnames';
import { HTMLAttributes, ReactNode } from 'react';
import Masonry from 'react-masonry-css';


export interface MasonryLayoutProps<T> extends HTMLAttributes<HTMLDivElement> {
  data: T[];
  render: (data: T, index: number) => ReactNode;
}

export const MasonryLayout = <T, >({ data, render, ...others }: MasonryLayoutProps<T>) => {
  return (
    <Masonry
      { ...others }
      className={classNames(
        'MansoryLayout',
        others.className
      )}
      breakpointCols={3}
      columnClassName="MasonryLayout__Item"
      css={css`
        display: flex;
        width: auto;
        margin: -12px;
      `}
    >
      {
        data.map((data, index) => {
          return (
            <div
              key={index}
              className="MasonryLayout__item"
              css={css`
                padding: 12px;
              `}
            >
              {
                render ? render(data, index) : <></>
              }
            </div>
          );
        })
      }
    </Masonry>
  );
};
