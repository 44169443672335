import { css } from '@emotion/react';
import classNames from 'classnames';
import { HTMLAttributes } from 'react';

export type HorizontalScrollableLayoutProps = HTMLAttributes<HTMLDivElement>

export const HorizontalScrollableLayout = ({ ...others }: HorizontalScrollableLayoutProps) => {
  return (
    <div
      { ...others }
      className={classNames(
        'HorizontalScrollableLayout',
        others.className
      )}
      css={css`
        display: flex;
        align-items: flex-start;
        overflow-x: auto;
      `}
    >
      { others.children }
    </div>
  );
};
