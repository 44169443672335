import { css } from '@emotion/react';
import { Typography, useTheme } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { HTMLAttributes, useState } from 'react';

import { Button } from '~/src/components/app/Button/v2';
import { Icon } from '~/src/components/app/Icon/v2';
import { Link } from '~/src/components/app/Link';
import { TemplateBrowseProvider } from '~/src/components/business/TemplateBrowse/context/context';
import { TemplateBrowse } from '~/src/components/business/TemplateBrowse/TemplateBrowse';
import { AmplitudeTaxonomyClient } from '~/src/helpers/AmplitudeTaxonomyClient';


export type TrendBrowseSectionProps = HTMLAttributes<HTMLDivElement>

export const TemplateBrowseArticle = (props: TrendBrowseSectionProps) => {
  const { t } = useTranslation('welcome');

  const theme = useTheme();
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>('');

  // 택소노미를 전송합니다.
  const discoverLogos = () => {
    AmplitudeTaxonomyClient.clickDiscoverMoreLogos();
  };

  return (
    <article
      { ...props }
      className={classNames(
        'TrendBrowseSection',
        props.className
      )}
      css={css`
        overflow-x: hidden;
        padding: 96px 0 24px;
      `}
    >
      <div
        className="TrendBrowseSection__Introduction"
        css={css`
          text-align: center;
          margin-bottom: 48px;
        `}
      >
        <Typography
          className="TrendBrowseSection__Title"
          variant="h3"
          component={'div'}
        >
          { t('welcome:trend.title') }
        </Typography>
      </div>
      <TemplateBrowseProvider
        selectedCategoryId={selectedCategoryId}
        setSelectedCategoryId={setSelectedCategoryId}
      >
        <TemplateBrowse
          location="main_home"
        />
      </TemplateBrowseProvider>
      <div
        className="TrendBrowseSection__Discover"
        css={css`
          padding: 62px 0px 85px;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <Button
          variant="outlined"
          size="medium"
          href={`/logo-preview?industry=${selectedCategoryId}`}
          LinkComponent={Link}
          onClick={discoverLogos}
          css={css`
            background-color: ${theme.app.colors['neutral-color-03']} !important;
          `}
        >
          <Typography
            variant="button5"
            css={css`
              color: ${theme.app.colors['neutral-color-10']};
            `}
          >
            { t('welcome:trend.more') }
          </Typography>
          <Icon
            width={24}
            height={24}
            css={css`
              margin-left: 10px;
            `}
          >
            ic_next
          </Icon>
        </Button>
      </div>
    </article>
  );
};
