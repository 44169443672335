import { css } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { HTMLAttributes, useEffect } from 'react';

import { AmplitudeTaxonomyClient } from '~/src/helpers/AmplitudeTaxonomyClient';
import { useMediaQuery } from '~/src/hooks/v1/Common/useMediaQuery';

import { OnboardingShowTrigger } from '../../app/OnboardingProvider/OnboardingShowTrigger';
import { PageHead } from '../../app/PageHead';
import { BenefitsSection } from './sections/BenefitsSection/BenefitsSection';
import { CompareSection } from './sections/CompareSection/CompareSection';
import { ExploreSection } from './sections/ExploreSection/ExploreSection';
import { HeroArticle } from './sections/HeroArticle/HeroArticle';
import { IntroduceArticle } from './sections/IntroduceArticle/IntroduceArticle';
import { MasonryReviewsArticle } from './sections/MasonryReviewArticle/MasonryReviewArticle';
import { QuestionsSection } from './sections/QuestionsSection/QuestionsSection';
import { TemplateBrowseArticle } from './sections/TemplateBrowseArticle/TemplateBrowseArticle';

export type WelcomeScreenProps = HTMLAttributes<HTMLDivElement>

export const WelcomeScreen = (props: WelcomeScreenProps) => {
  const { t } = useTranslation(['welcome']);
  const laptop = useMediaQuery('laptop');

  useEffect(() => {
    AmplitudeTaxonomyClient.visitMainHome();
  }, []);

  return (
    <section
      className={classNames(
        'WelcomeScreen',
        props.className
      )}
      style={props.style}
      { ...props }
      css={css`
        width: 100%;
        overflow: hidden;
      `}
    >
      <PageHead
        title={t('welcome:meta.title')}
        description={t('welcome:meta.description')}
      />
      <HeroArticle />
      { !laptop.down.is && <TemplateBrowseArticle /> }
      <IntroduceArticle />
      <OnboardingShowTrigger />
      <CompareSection />
      <MasonryReviewsArticle />
      { !laptop.down.is ? <BenefitsSection /> : <ExploreSection /> }
      <QuestionsSection />
    </section>
  );
};
