import { PropsWithChildren, useCallback } from 'react';

import { ComponentProps } from '~/src/types/common';

import { InViewContainer } from '../InViewContainer/InViewContainer';
import { OnInView } from '../InViewContainer/InViewContainer.types';
import { useOnboardingController } from '../OnboardingRequest/hooks/useOnboardingController';

/**
 * 특정 영역까지 스크롤 시 온보딩 요청 팝업이 노출되어야 할 때 사용하는 컴포넌트
 * @link [온보딩 요청 팝업 문서](https://www.notion.so/pixo/LWS-SBL-Meeting-04e04d6969674947b84933d1bd1328bf?p=cb10bdb0b1a240dd85ca3d5c051c9bb1&pm=s)
 */
export const OnboardingShowTrigger = (props: PropsWithChildren & ComponentProps) => {
  const { onShowOnboardingRequestPopup } = useOnboardingController();

  const onInView = useCallback<OnInView>((isInView) => {
    if (isInView) {
      onShowOnboardingRequestPopup();
    }
  }, [onShowOnboardingRequestPopup]);

  return (
    <InViewContainer
      {...props}
      inViewOptions={{
        once: true,
      }}
      onInView={onInView}
    />
  );
};
