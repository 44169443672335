import { css, useTheme } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { HTMLAttributes } from 'react';

import { TemplateCTACard } from '~/src/components/business/TemplateCTACard';
import { TAXONOMY_EVENT_TYPE_CLICK_CTA_MAIN_HOME } from '~/src/constants/Taxonomy';
import { useMediaQuery } from '~/src/hooks/v1/Common/useMediaQuery';

import { BenefitsSectionBenefits } from './components/BenefitsSectionBenefits';
import { BenefitsSectionTitleContainer } from './components/BenefitsSectionTitleContainer';

export type BenefitsSectionProps = HTMLAttributes<HTMLDivElement>

export const BenefitsSection = (props: BenefitsSectionProps) => {
  const { t } = useTranslation('common');
  const theme = useTheme();
  const laptop = useMediaQuery('laptop');

  return (
    <section
      { ...props }
      className={classNames(
        'BenefitsSection',
        props.className
      )}
      style={props.style}
      css={css`
        background-color: ${theme.app.colors['neutral-color-09']};
      `}
    >
      <div
        className="BenefitsSection__Wrapper"
        css={css`
          max-width: 1136px;
          padding: 104px 45px 140px;
          margin: auto;
          ${laptop.down.mq} {
            padding: 0 20px;
          }
        `}
      >
        <BenefitsSectionTitleContainer />
        <BenefitsSectionBenefits
          css={css`
            margin-top: 38px;
            margin-bottom: 28px;
          `}
        />
        <TemplateCTACard
          title={t('common:explore.title')}
          exploreButtonLabel={t('common:explore.cta')}
          taxonomy={TAXONOMY_EVENT_TYPE_CLICK_CTA_MAIN_HOME}
        />
      </div>
    </section>
  );
};
