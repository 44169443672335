import { useInView } from 'framer-motion';
import { memo, useEffect, useRef } from 'react';

import { InViewContainerProps } from './InViewContainer.types';

export const InViewContainer = memo((props: InViewContainerProps) => {
  const { disabled, children } = props;

  if (disabled) {
    return <>{children}</>;
  }

  return <InViewInner {...props} />;
});

const InViewInner = memo((props: InViewContainerProps) => {
  const { children, inViewOptions, onInView, ...others } = props;

  const elementRef = useRef<HTMLDivElement>(null);

  const isInView = useInView(elementRef, inViewOptions);

  useEffect(() => {
    onInView?.(isInView, elementRef.current ?? undefined);
  }, [isInView, onInView]);

  return (
    <div
      ref={elementRef}
      {...others}
    >
      {children}
    </div>
  );
});
