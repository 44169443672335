import { css, Typography, useTheme  } from '@mui/material';
import classNames from 'classnames';
import Image from 'next/image';
import { HTMLAttributes } from 'react';

import StarImage from '@/assets/images/star@3x.png';

export interface ReviewCardProps extends HTMLAttributes<HTMLDivElement> {
  author?: string;
  content?: string;
  thumbnail?: string;
}

export const ReviewCard = ({ author, content, thumbnail, ...others }: ReviewCardProps) => {
  const theme = useTheme();

  return (
    <div
      { ...others }
      className={classNames(
        'ReviewCard',
        others.className
      )}
      css={css`
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        background-color: ${theme.app.colors['neutral-color-10']};
        padding: 32px 30px;
      `}
    >
      <div className="ReviewCard__AuthorWrapper">
        <Typography
          className="ReviewCard__Author"
          variant="body2"
          css={css`
            margin-bottom: 10px;
          `}
        >
          { author }
        </Typography>
      </div>
      <div
        className="ReviewCard__ScoreWrapper"
        css={css`
          margin-bottom: 16px;
        `}
      >
        <Image
          src={StarImage.src}
          alt="5 Rating"
          width={100}
          height={16}
          css={css`
            max-width: 100px;
            width: 100%;
            height: auto;
          `}
        />
      </div>
      <div
        className="ReviewCard__ContentWrapper"
        css={css`
          flex: 1;
        `}
      >
        <Typography
          variant="body7"
          css={css`
            margin-bottom: 10px;
            color: ${theme.app.colors['neutral-color-01']};
          `}
        >
          { content }
        </Typography>
      </div>
      <div className="ReviewCard__TemplateWrapper">
        <div
          className="ReviewCard__TemplateContainer"
          css={css`
            width: 132px;
            height: 132px;
            border-radius: 10px;
            background-color: ${theme.app.colors['neutral-color-06']};
          `}
        >
          {
            thumbnail &&
              <Image
                src={thumbnail}
                alt={'Beautiful Template'}
                width={132}
                height={132}
                css={css`
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                `}
              />
          }
        </div>
      </div>
    </div>
  );
};
