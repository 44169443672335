import { css, styled, Typography } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { HTMLAttributes } from 'react';

import { FAQAccordion, FAQAccordionContent } from '~/src/components/business/FAQAccordion';
import { TAXONOMY_CLICK_FAQ_LOCATION_MAIN, TAXONOMY_CLICK_FAQ_PROPERTY_LOCATION, TAXONOMY_CLICK_FAQ_PROPERTY_QUESTION, TAXONOMY_CLICK_FAQ_QUESTION_EDIT_AFTER_CREATE, TAXONOMY_CLICK_FAQ_QUESTION_FORMATS_AVAILABLE, TAXONOMY_CLICK_FAQ_QUESTION_HOW_DO_I_USE, TAXONOMY_CLICK_FAQ_QUESTION_LICENSING_TERMS, TAXONOMY_CLICK_FAQ_QUESTION_REMOVE_WATERMARK } from '~/src/constants/Taxonomy';
import { useMediaQuery } from '~/src/hooks/v1/Common/useMediaQuery';
import { nl2brForReact } from '~/src/utils/common';


export type QuestionsSectionProps = HTMLAttributes<HTMLDivElement>

export const QuestionsSection = (props: QuestionsSectionProps) => {
  const laptop = useMediaQuery('laptop');
  const { t } = useTranslation('common');

  return (
    <section
      { ...props }
      className={classNames(
        'QuestionsSection',
        props.className
      )}
    >
      <article
        className="QuestionsSection__Wrapper"
        css={css`
          max-width: 1136px;
          padding: 120px 45px 230px;
          margin: auto;
          ${laptop.down.mq} {
            padding: 60px 20px;
          }
        `}
      >
        <div
          className="QuestionsSection__TitleContainer"
          css={css`
            margin-bottom: 55px;
          `}
        >
          <Typography
            variant="h3"
            css={css`
              text-align: center;
            `}
          >
            {
              t('common:faq.title')
            }
          </Typography>
        </div>
        <div
          className="QuestionsSection__QuestionsContainer"
        >
          <FAQAccordionContainer
            title={t('common:faq.items.0.title') as string}
            content={nl2brForReact(t('faq.items.0.content'))}
            taxonomyEvents={{
              click_faq: {
                [TAXONOMY_CLICK_FAQ_PROPERTY_LOCATION]: TAXONOMY_CLICK_FAQ_LOCATION_MAIN,
                [TAXONOMY_CLICK_FAQ_PROPERTY_QUESTION]: TAXONOMY_CLICK_FAQ_QUESTION_HOW_DO_I_USE,
              },
            }}
          />
          <FAQAccordionContainer
            title={t('common:faq.items.1.title') as string}
            content={nl2brForReact(t('faq.items.1.content'))}
            taxonomyEvents={{
              click_faq: {
                [TAXONOMY_CLICK_FAQ_PROPERTY_LOCATION]: TAXONOMY_CLICK_FAQ_LOCATION_MAIN,
                [TAXONOMY_CLICK_FAQ_PROPERTY_QUESTION]: TAXONOMY_CLICK_FAQ_QUESTION_EDIT_AFTER_CREATE,
              },
            }}
          />
          <FAQAccordionContainer
            title={t('common:faq.items.2.title') as string}
            content={nl2brForReact(t('faq.items.2.content'))}
            taxonomyEvents={{
              click_faq: {
                [TAXONOMY_CLICK_FAQ_PROPERTY_LOCATION]: TAXONOMY_CLICK_FAQ_LOCATION_MAIN,
                [TAXONOMY_CLICK_FAQ_PROPERTY_QUESTION]: TAXONOMY_CLICK_FAQ_QUESTION_REMOVE_WATERMARK,
              },
            }}
          />
          <FAQAccordionContainer
            title={t('common:faq.items.3.title') as string}
            content={nl2brForReact(t('faq.items.3.content'))}
            taxonomyEvents={{
              click_faq: {
                [TAXONOMY_CLICK_FAQ_PROPERTY_LOCATION]: TAXONOMY_CLICK_FAQ_LOCATION_MAIN,
                [TAXONOMY_CLICK_FAQ_PROPERTY_QUESTION]: TAXONOMY_CLICK_FAQ_QUESTION_FORMATS_AVAILABLE,
              },
            }}
          />
          <FAQAccordionContainer
            title={t('common:faq.items.4.title') as string}
            content={(
              <FAQAccordionContent
                i18nextKey={'common:faq.items.4.content'}
              />
            )}
            taxonomyEvents={{
              click_faq: {
                [TAXONOMY_CLICK_FAQ_PROPERTY_LOCATION]: TAXONOMY_CLICK_FAQ_LOCATION_MAIN,
                [TAXONOMY_CLICK_FAQ_PROPERTY_QUESTION]: TAXONOMY_CLICK_FAQ_QUESTION_LICENSING_TERMS,
              },
            }}
          />
        </div>
      </article>
    </section>
  );
};

const FAQAccordionContainer = styled(FAQAccordion)`
  margin-bottom: 14px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;
