import { css } from '@mui/material';
import classNames from 'classnames';
import Image from 'next/image';
import { HTMLAttributes } from 'react';

import LeftHeroImage from '@/assets/images/bg_logo-01@3x.png';
import CenterHeroImage from '@/assets/images/bg_logo-02@3x.png';
import RightHeroImage from '@/assets/images/bg_logo-03@3x.png';

export type FloatingLogosProps = HTMLAttributes<HTMLDivElement>

export const FloatingLogos = (props: FloatingLogosProps) => {
  return (
    <div
      { ...props }
      className={classNames(
        'FloatingLogos',
        props.className
      )}
      css={css`
        width: 100%;
        height: 100%;
        position: absolute;
        left: 50%;
        top: 0;
        pointer-events: none;
        max-width: 100%;
        min-width: 1570px;
        transform: translateX(-50%);
      `}
    >
      <Image
        src={LeftHeroImage.src}
        alt=""
        width={507}
        height={731}
        priority
        css={css`
          width: auto;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
        `}
      />
      <Image
        src={CenterHeroImage.src}
        alt=""
        width={504}
        height={142}
        priority
        css={css`
          width: 504px;
          height: auto;
          position: absolute;
          right: 50%;
          bottom: 0;
          transform: translateX(50%);
        `}
      />
      <Image
        src={RightHeroImage.src}
        alt=""
        width={507}
        height={749}
        priority
        css={css`
          width: auto;
          height: 100%;
          position: absolute;
          right: 0;
          bottom: 0;
        `}
      />
    </div>
  );
};
