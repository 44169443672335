import { css, Grid, Typography, useTheme   } from '@mui/material';
import classNames from 'classnames';
import Image from 'next/image';
import { HTMLAttributes, ReactNode } from 'react';

import { AspectRatio } from '~/src/components/app/AspectRatio';
import { useMediaQuery } from '~/src/hooks/v1/Common/useMediaQuery';

export interface SingleImageIntroductionProps extends HTMLAttributes<HTMLDivElement> {
  title?: string;
  description?: ReactNode;
  image?: string;
}

export const SingleImageIntroduction = ({ title, description, image, ...others }: SingleImageIntroductionProps) => {
  const theme = useTheme();
  const laptop = useMediaQuery('laptop');

  return (
    <Grid
      { ...others }
      className={classNames(
        'SingleImageIntroduction',
        others.className
      )}
      justifyContent={'space-between'}
      container
    >
      <Grid
        laptop={5}
        mobile={12}
        css={css`
          display: flex;
          align-items: center;
          align-self: stretch;
          ${laptop.down.mq} {
            order: 2;
            margin-top: 50px;
          }
        `}
      >
        <div className="Content">
          <Typography
            variant={laptop.down.is ? 'h4' : 'h3'}
            css={css`
              margin-bottom: 32px;
            `}
          >
            { title }
          </Typography>
          <Typography
            variant="body4"
            css={css`
              color: ${theme.app.colors['neutral-color-06']};
            `}
          >
            { description }
          </Typography>
        </div>
      </Grid>
      <Grid
        laptop={6}
        mobile={12}
      >
        {
          image &&
            <AspectRatio
              ratio="516:353"
            >
              <Image
                src={image}
                alt=""
                width={516}
                height={353}
                css={css`
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: 10px;
                `}
              />
            </AspectRatio>
        }
      </Grid>
    </Grid>
  );
};
