import { css, Grid, styled, Typography, useMediaQuery, useTheme    } from '@mui/material';
import classNames from 'classnames';
import Image from 'next/image';
import { HTMLAttributes, ReactNode } from 'react';

import { AspectRatio } from '~/src/components/app/AspectRatio';

export interface MultipleImageIntroduction extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
  title?: ReactNode;
  description?: string;
  images?: string[];
}

export const MultipleImageIntroduction = ({ images, description, title, ...others }: MultipleImageIntroduction) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('laptop'));

  return (
    <Grid
      { ...others }
      className={classNames(
        'MultipleImageIntroduction',
        others.className
      )}
      container
      justifyContent={'space-between'}
    >
      <Grid
        laptop={5}
        mobile={12}
      >
        <ImageGridContainer>
          {
            images?.map((image, key) => {
              return (
                <ImageItemContainer
                  key={key}
                >
                  <AspectRatio
                    ratio="208:176"
                  >
                    <Image
                      src={image}
                      alt=""
                      width={208}
                      height={176}
                      css={css`
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 10px;
                      `}
                    />
                  </AspectRatio>
                </ImageItemContainer>
              );
            })
          }
        </ImageGridContainer>
      </Grid>
      <Grid
        laptop={6}
        css={css`
          display: flex;
          align-items: center;
          align-self: stretch;
          ${theme.breakpoints.down('laptop')} {
            margin-top: 50px;
          }
        `}
      >
        <div className="Content">
          <Typography
            variant={isTablet ? 'h4' : 'h3'}
            css={css`
              margin-bottom: 30px;
            `}
          >
            { title }
          </Typography>
          <Typography
            variant="body4"
            css={css`
              color: ${theme.app.colors['neutral-color-06']};
            `}
          >
            { description }
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

const ImageGridContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
`;

const ImageItemContainer = styled('div')`
  width: 50%;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
`;
