import { css, styled, Typography, useTheme } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { HTMLAttributes } from 'react';

import EasyEditorImage from '@/assets/images/easy-logo-editor@3x.png';
import HighResolutionFirstImage from '@/assets/images/high-resolution_1@3x.png';
import HighresolutionSecondImage from '@/assets/images/high-resolution_2@3x.png';
import ProfessinalFirstImage from '@/assets/images/img_professional_1@3x.png';
import ProfessionalSecondImage from '@/assets/images/img_professional_2@3x.png';
import ProfessionalThirdImage from '@/assets/images/img_professional_3@3x.png';
import ProfessionalFourthImage from '@/assets/images/img_professional_4@3x.png';
import { useMediaQuery } from '~/src/hooks/v1/Common/useMediaQuery';
import { nl2brForReact } from '~/src/utils/common';

import { MultipleImageIntroduction } from '../../components/MultipleImageIntroduction/MultipleImageIntroduction';
import { SingleImageIntroduction } from '../../components/SingleImageIntroduction/SingleImageIntroduction';

export type IntroduceSectionProps = HTMLAttributes<HTMLDivElement>

export const IntroduceArticle = (props: IntroduceSectionProps) => {
  const { t } = useTranslation('welcome');
  const theme = useTheme();
  const laptop = useMediaQuery('laptop');

  return (
    <article
      { ...props }
      className={classNames(
        'IntroduceSection',
        props.className
      )}
      css={css`
        max-width: 1136px;
        width: 100%;
        margin: auto;
        padding: 85px 45px 160px;
        ${laptop.down.mq} {
          padding: 60px 20px;
        }
      `}
    >
      <div
        className="IntroduceSection__TitleWrapper"
        css={css`
          text-align: center;
          margin-bottom: 66px;
        `}
      >
        <Typography
          variant={laptop.down.is ? 'h3' : 'h2'}
          css={css`
            color: ${theme.app.colors['neutral-color-01']};
            margin-bottom: 16px;
          `}
        >
          { nl2brForReact(t('introduction.title')) }
        </Typography>
        <Typography
          variant="body3"
          className="IntroduceSection__Description"
          css={css`
            color: ${theme.app.colors['neutral-color-06']};
          `}
        >
          { nl2brForReact(t('introduction.description')) }
        </Typography>
      </div>
      <IntroductionContainer>
        <MultipleImageIntroduction
          title={nl2brForReact(t('introduction.sections.0.title'))}
          description={t('introduction.sections.0.description') as string}
          images={[
            ProfessinalFirstImage.src,
            ProfessionalSecondImage.src,
            ProfessionalThirdImage.src,
            ProfessionalFourthImage.src,
          ]}
        />
      </IntroductionContainer>
      <IntroductionContainer>
        <SingleImageIntroduction
          title={t('introduction.sections.1.title') as string}
          description={nl2brForReact(t('introduction.sections.1.description'))}
          image={EasyEditorImage.src}
        />
      </IntroductionContainer>
      <IntroductionContainer>
        <MultipleImageIntroduction
          title={nl2brForReact(t('introduction.sections.2.title'))}
          description={t('introduction.sections.2.description') as string}
          images={[
            HighResolutionFirstImage.src,
            HighresolutionSecondImage.src,
          ]}
        />
      </IntroductionContainer>
    </article>
  );
};

const IntroductionContainer = styled('article')`
  margin-bottom: 210px;
  &:last-of-type {
    margin-bottom: 0;
  }
  ${(props) => {
    return (`
      ${props.theme.breakpoints.down('laptop')} {
        margin-bottom: 120px;
      }
    `);
  }}
`;
