import { DehydratedState } from '@tanstack/react-query';
import { GetStaticPropsContext } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { NextPageWithLayout } from '~/src/types/common';

import { NormalLayout } from '../components/layouts/Normal/NormalLayout';
import { WelcomeScreen } from '../components/screens/Welcome/WelcomeScreen';


export interface IndexPageProps {
  dehydratedState?: DehydratedState;
}

export const Index: NextPageWithLayout<IndexPageProps> = () => {
  return (
    <WelcomeScreen />
  );
};

Index.Layout = (page) => {
  return (
    <NormalLayout>
      { page }
    </NormalLayout>
  );
};

export const getStaticProps = async ({ locale }: GetStaticPropsContext) => {
  return {
    props: {
      ...(
        await serverSideTranslations(
          locale ?? 'en',
          ['common', 'welcome']
        )
      ),
    },
  };
};

export default Index;
