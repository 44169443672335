import { useCallback, useEffect, useRef, useState } from 'react';

export const useRelativePosition = () => {
  const ref = useRef<HTMLDivElement>(null);
  const rect = useRef<DOMRect>();
  const [isTracking, setTracking] = useState(false);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  const onTracking = useCallback((event: MouseEvent) => {
    if (ref.current === null) return;
    if (isTracking === false) setTracking(true);
    rect.current = ref.current.getBoundingClientRect();

    const x = event.pageX / (rect.current.left + rect.current.width);
    const y = event.pageY / (rect.current.top + rect.current.height);

    setX(x);
    setY(y);
  }, [
    isTracking,
  ]);

  useEffect(() => {
    const target = ref.current;

    target?.addEventListener('mousemove', onTracking);

    return () => {
      target?.removeEventListener('mousemove', onTracking);
    };
  }, [onTracking]);

  return {
    ref,
    isTracking,
    x,
    y,
  };
};
